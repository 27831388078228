exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-autosubscribe-tsx": () => import("./../../../src/pages/autosubscribe.tsx" /* webpackChunkName: "component---src-pages-autosubscribe-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-elearning-development-tsx": () => import("./../../../src/pages/elearning-development.tsx" /* webpackChunkName: "component---src-pages-elearning-development-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-prompt-alkotas-alapjai-chatgpt-tsx": () => import("./../../../src/pages/prompt-alkotas-alapjai-chatgpt.tsx" /* webpackChunkName: "component---src-pages-prompt-alkotas-alapjai-chatgpt-tsx" */),
  "component---src-pages-regisztracio-tsx": () => import("./../../../src/pages/regisztracio.tsx" /* webpackChunkName: "component---src-pages-regisztracio-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-webinar-registration-tsx": () => import("./../../../src/pages/webinar-registration.tsx" /* webpackChunkName: "component---src-pages-webinar-registration-tsx" */),
  "component---src-pages-webinar-regisztracio-tsx": () => import("./../../../src/pages/webinar-regisztracio.tsx" /* webpackChunkName: "component---src-pages-webinar-regisztracio-tsx" */),
  "component---src-pages-workshop-tsx": () => import("./../../../src/pages/workshop.tsx" /* webpackChunkName: "component---src-pages-workshop-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-tutorial-post-tsx": () => import("./../../../src/templates/tutorial-post.tsx" /* webpackChunkName: "component---src-templates-tutorial-post-tsx" */)
}

