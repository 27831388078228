// Saaspik template CSS files
import './static/template-dependencies/bootstrap/css/bootstrap.min.css';
import './static/template-dependencies/fontawesome/css/all.min.css';
import './static/template-dependencies/swiper/css/swiper.min.css';
import './static/template-dependencies/wow/css/animate.css';
import './static/template-dependencies/magnific-popup/css/magnific-popup.css';
import './static/template-dependencies/components-elegant-icons/css/elegant-icons.min.css';
import './static/template-dependencies/simple-line-icons/css/simple-line-icons.css';
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const RootApp = ({ element }) => {
  React.useEffect(() => {
    const PIXELSIGNS = window.PIXELSIGNS;

    PIXELSIGNS.documentOnReady.init();
    PIXELSIGNS.documentOnLoad.init();
    window.$(window).on('resize', PIXELSIGNS.documentOnResize.init);
    window.$(window).on('scroll', PIXELSIGNS.documentOnScroll.init);
  }, []);

  return <>{element}</>;
};

export const wrapRootElement = ({ element }) => {

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      redirectUri={process.env.GATSBY_AUTH0_CALLBACK_BASE_URI}
      audience={process.env.GATSBY_AUTH0_AUDIENCE}
      scope={process.env.GATSBY_AUTH0_SCOPE}
    >
      <RootApp element={element} />
    </Auth0Provider>
  );
};
